import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { width: "80%", "margin-left": "7%" } },
    [
      _c(
        VCard,
        { attrs: { flat: "" } },
        [
          _c(
            VTabs,
            { attrs: { vertical: "" } },
            [
              _c(
                VTab,
                { staticStyle: { "justify-content": "flex-start" } },
                [
                  _c(VIcon, { attrs: { left: "" } }, [
                    _vm._v(
                      "\n                        mdi-account\n                    "
                    ),
                  ]),
                  _vm._v(
                    "\n                   " +
                      _vm._s(_vm.$t("word.terms")) +
                      "\n                "
                  ),
                ],
                1
              ),
              _c(
                VTab,
                { staticStyle: { "justify-content": "flex-start" } },
                [
                  _c(VIcon, { attrs: { left: "" } }, [
                    _vm._v(
                      "\n                        mdi-lock\n                    "
                    ),
                  ]),
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("word.privacy")) +
                      "\n                "
                  ),
                ],
                1
              ),
              _c(
                VTabItem,
                [
                  _c(
                    VCard,
                    { attrs: { flat: "" } },
                    [
                      _c(
                        VCardText,
                        [
                          _c("vue-markdown", {
                            domProps: {
                              innerHTML: _vm._s(_vm.$t("provision.terms")),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VTabItem,
                [
                  _c(
                    VCard,
                    { attrs: { flat: "" } },
                    [
                      _c(
                        VCardText,
                        [
                          _c("vue-markdown", {
                            domProps: {
                              innerHTML: _vm._s(_vm.$t("provision.privacy")),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }