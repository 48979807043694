<template>
    <div style="width: 80%; margin-left: 7%;">
        <v-card flat>
            <v-tabs vertical>
                <v-tab style="justify-content: flex-start;">
                    <v-icon left>
                        mdi-account
                    </v-icon>
                   {{$t('word.terms')}}
                </v-tab>
                <v-tab style="justify-content: flex-start;">
                    <v-icon left>
                        mdi-lock
                    </v-icon>
                    {{$t('word.privacy')}}
                </v-tab>

                <v-tab-item>
                    <v-card flat>
                        <v-card-text>
                            <vue-markdown v-html="$t('provision.terms')"></vue-markdown>
<!--                            <h2>제 1 장 총 칙</h2>-->
<!--                            <p>-->
<!--                                <br/><br/>-->
<!--                                제1조 [목적]<br/>-->
<!--                                본 약관은 주식회사 유엔진솔루션즈(이하 “회사”라 합니다)가 웹사이트(www.msaez.io)를 통하여 제공하는 교육정보서비스(이하 “서비스”라 합니다)의-->
<!--                                이용과 관련하여 회사와 회원 사이에 권리ㆍ의무 및 책임사항 등을 규정함을 목적으로 합니다.-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                제2조 [정의]<br/>-->
<!--                                ① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br/>-->
<!--                                &emsp;&emsp;1. “이용자”라 함은 회사의 웹사이트에 접속하여 본 약관에 따라 회사가 제공하는 콘텐츠 및 제반서비스를-->
<!--                                이용하는 회원 및 비회원을 말합니다.-->
<!--                                &emsp;&emsp;2. “회원”이라 함은 회사의 웹사이트에 접속하여 본 약관에 동의함으로써 회사와 이용계약을 체결하고-->
<!--                                아이디(ID)를 부여받은 자로서 회사가 제공하는 정보와 서비스를 지속적으로 이용할 수 있는 자를 말합니다.<br/>-->
<!--                                &emsp;&emsp;3. “콘텐츠”라 함은 회사가 제작하여 웹사이트에서 제공 및 판매하는 온라인 강좌 및 기타 관련정보를-->
<!--                                의미함으로서, 정보통신망이용촉진 및 정보보호 등에 관한 법률 제2조 제1항 제1호의 규정에 의한-->
<!--                                정보통신망에서 사용되는 부호ㆍ문자ㆍ음성ㆍ음향ㆍ이미지 또는 영상 등으로 표현된 자료 또는 정보를 말합니다.<br/>-->
<!--                                &emsp;&emsp;4. “아이디(ID)”라 함은 회원의 식별 및 서비스 이용을 위하여 회원이 정하고 회사가 승인하는 문자 또는-->
<!--                                숫자의 조합을 말합니다.<br/>-->
<!--                                &emsp;&emsp;5. “비밀번호(PASSWORD)”라 함은 서비스 이용 시, 아이디와 일치되는 회원임을 확인하고 회원 개인정보-->
<!--                                보호를 위하여 회원 자신이 정한 문자 또는 숫자의 조합을 말합니다.<br/>-->
<!--                                &emsp;&emsp;6. “전자우편(Email)”이라 함은 인터넷을 통한 우편 혹은 전기적 매체를 이용한 우편을 말합니다.<br/>-->
<!--                                &emsp;&emsp;7. “운영자(관리자)”라 함은 서비스의 전반적인 관리와 원활한 운영을 위하여 회사에서 선정한 사람 또는-->
<!--                                회사를 말합니다.<br/>-->
<!--                                &emsp;&emsp;8. “회원의 게시물”이라 함은 회사의 서비스가 제공되는 웹사이트에 회원이 올린 글, 이미지, 각종 파일 및 링크,-->
<!--                                각종 덧글 등의 정보를 의미합니다.<br/>-->
<!--                                &emsp;&emsp;9. “사이버머니”라 함은 회사 웹사이트 내에서 콘텐츠 등을 구매할 수 있는 가상의 지불수단을 말합니다.-->
<!--                                코인 등 그 명칭에 따라 결제 가능한 범위의 차이가 있을 수 있습니다.-->
<!--                                ① 전항 각호에 해당하는 정의 이외의 기타 용어의 정의에 대하여는 거래 관행 및 관계 법령에 따릅니다.<br/>-->
<!--                                &emsp;&emsp;10. “사용시간”라 함은 회사 웹사이트 내에서 콘텐츠 이용 등을 지불할 수 있는 지불수단을 말합니다.-->
<!--                                컨설팅시간, IDE시간 등 그 명칭에 따라 결제 가능한 범위의 차이가 있을 수 있습니다.-->
<!--                                ① 전항 각호에 해당하는 정의 이외의 기타 용어의 정의에 대하여는 거래 관행 및 관계 법령에 따릅니다.<br/><br/>-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                제3조 [회사 신원정보 등의 제공]<br/>-->
<!--                                회사는 회사의 상호, 대표자 성명, 주소, 전화번호(소비자의 불만을 처리하는 곳의 연락처 포함), 모사전송번호, 전자우편주소, 사업자등록번호, 통신판매업-->
<!--                                신고번호 및 개인정보관리책임자 등을 이용자가 쉽게 알 수 있도록 온라인 서비스 초기화면에 게시합니다.<br/><br/>-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                제4조 [약관의 게시 등]<br/>-->
<!--                                ① 회사는 이용자가 별도의 연결화면을 통하여 약관을 확인할 수 있도록 회사 웹사이트에 게시하며, 약관의 전문을 출력할 수 있도록 하고 있습니다.<br/>-->
<!--                                ② 회사는 약관의 중요한 내용을 굵은 문자 등으로 명확하게 표시하여 이용자가 알아보기 쉽도록 하고 있습니다.<br/><br/>-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                제5조 [약관의 개정]<br/>-->
<!--                                ① 회사는 관계법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.<br/>-->
<!--                                ② 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여, 개정 전 약관과 함께 적용일 14일 전부터 회사 웹사이트에서 확인할 수 있도록 게시하며,-->
<!--                                기존 회원에게는 본 약관 제7조의 방법을 통하여 약관 개정사실을 통지합니다.<br/>-->
<!--                                ③ 기존 회원이 변경된 약관에 동의하지 않는 경우 서비스 이용을 중단하고 회원탈퇴를 할 수 있습니다.<br/><br/>-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                제6조 [약관의 효력]<br/>-->
<!--                                ① 본 약관은 이용자가 약관의 내용에 동의하며 회원가입을 신청하고, 회사가 그 신청에 대하여 승낙함으로써 효력이 발생합니다.<br/>-->
<!--                                ② 회사는 기존 회원이 본 약관 제5조 제2항에 따른 공지 또는 통지에도 불구하고, 동 기간 내에 이의를 제기하지 않는 경우에는 변경된 약관을 승인한 것으로-->
<!--                                봅니다.<br/>-->
<!--                                ③ 본 약관에 명시되지 않은 사항에 대해서는 “약관의 규제에 관한 법률”, “정보통신망 이용촉진 및 정보보호 등에 관한 법률”, “전자상거래 등에서의-->
<!--                                소비자보호에 관한 법률”, “학원의 설립·운영 및 과외교습에 관한 법률”, “콘텐츠 산업 진흥법” 및 기타 관련법령 또는 상관례에 따릅니다.<br/><br/>-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                제7조 [“회원”에 대한 통지]<br/>-->
<!--                                ① 회사는 회원에게 알려야 할 사항이 발생하는 경우, 회원가입 시 회원이 공개한 전자우편주소 또는 쪽지, 팝업창, 유ㆍ무선 등의 방법으로 통지할 수 있습니다.<br/>-->
<!--                                ② 회사는 회원 전체에 대한 통지의 경우 14일 이상 회사 웹사이트 게시판에 게시함으로써 전항의 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래 및-->
<!--                                사이트의 이용과 관련하여 중대한 영향을 미치는 사항에 대하여는 30일 이상 전항과 동일한 방법으로 별도 통지할 수 있습니다.<br/><br/>-->

<!--                            </p>-->
<!--                            <h2> 제 2 장 회원가입 </h2>-->
<!--                            <p>-->
<!--                                <br/>-->
<!--                                제8조 [회원가입]<br/>-->
<!--                                ① 회원으로 가입하여 회사 서비스의 이용을 희망하는 자는 약관의 내용을 숙지한 후 동의함을 표시하고, 회사가 제시하는 소정의 회원가입 양식에 관련사항을-->
<!--                                기재하여 회원가입을 신청하여야 합니다.<br/>-->
<!--                                ② 회사는 전항에 따라 이용자가 온라인 회원가입 신청양식에 기재하는 모든 회원정보를 실제 데이터인 것으로 간주합니다.<br/>-->
<!--                                ③ 실명이나 실제 정보를 입력하지 않은 회원은 법적인 보호를 받을 수 없으며, 본 약관의 관련 규정에 따라 서비스 사용에 제한을 받을 수 있습니다.<br/>-->
<!--                                ④ 회사는 본조 제1항에 따른 이용자의 신청에 대하여 회원가입을 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각호에 해당하는 신청에 대하여는 승낙을 하지-->
<!--                                않을 수 있으며, 승낙 이후라도 취소할 수 있습니다.<br/>-->
<!--                                &emsp;&emsp;1. 이용자의 귀책사유로 인하여 승인이 불가능한 경우<br/>-->
<!--                                &emsp;&emsp;2. 실명을 사용하지 않은 경우<br/>-->
<!--                                &emsp;&emsp;3. 타인의 명의 또는 개인정보를 도용하는 경우<br/>-->
<!--                                &emsp;&emsp;4. 허위의 정보를 제공하는 경우<br/>-->
<!--                                &emsp;&emsp;5. 중복된 아이디를 사용하는 경우<br/>-->
<!--                                &emsp;&emsp;6. 회사가 제시하는 회원가입 신청양식에 관련 내용을 기재하지 않은 경우<br/>-->
<!--                                &emsp;&emsp;7. 이전에 회사 이용약관 또는 관계법령을 위반하여 회원자격이 상실되었던 경우<br/>-->
<!--                                &emsp;&emsp;8. 본 약관 제19조 [회원의 의무]를 위반하는 경우<br/>-->
<!--                                &emsp;&emsp;9. 기타 본 약관 및 관계법령을 위반하는 경우<br/>-->
<!--                                &emsp;&emsp;10. 회원가입 신청양식에 기재되어 회사에 제공되는 개인정보(ID, 비밀번호, 주소 등)가 선량한 풍속 기타 사회질서에 위배되거나 타인을 모욕하는 경우<br/>-->
<!--                                ⑤ 회사는 서비스 관련 설비의 여유가 없거나 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.<br/>-->
<!--                                ⑥ 회사가 본조 제4항과 제5항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우에는 팝업창을 통하여 즉시 이용자(신청자)에게 알립니다. 단, 회사의-->
<!--                                귀책사유 없이 이용자에게 알릴 수 없는 경우에는 예외로 합니다.<br/>-->
<!--                                ⑦ 보호자(법정대리인)는 별도의 “학부모 회원가입”을 통하여 자녀의 학습 진도율 등을 확인할 수 있습니다.<br/><br/>-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                제9조 [만 14세 미만 아동의 회원가입에 관한 특칙]<br/>-->
<!--                                ① 만14세 미만의 이용자는 회사 웹사이트에서 전자적 표시 형태로 제공되는 개인정보처리방침을 충분히 숙지한 후, 반드시 보호자의 동의를 얻어 회원가입을 신청-->
<!--                                하여야 하는 등 본인의 개인정보를 제공함에 있어 신중을 기해야 합니다.<br/>-->
<!--                                ② 회사는 만 14세 미만의 이용자가 회원가입을 신청하는 경우 별도의 안내 또는 절차를 통하여 보호자의 동의 여부를 확인할 수 있습니다.<br/>-->
<!--                                ③ 만14세 미만의 이용자는 회사가 보호자에게 전항의 동의사실 여부를 확인 할 수 있도록 회원가입 시, 보호자의 성명 및 유ㆍ무선 연락처 등을 제공하여야-->
<!--                                합니다.<br/>-->
<!--                                ④ 회사는 보호자 동의에 대한 확인절차를 거치지 않은 만14세 미만 이용자에 대하여는 회원가입을 불허 또는 취소할 수 있습니다.<br/>-->
<!--                                ⑤ 만 14세 미만 회원의 보호자는 자녀에 대한 개인정보의 열람, 정정, 갱신을 요구하거나 회원가입에 대한 동의를 철회할 수 있으며, 이러한 경우에 회사는-->
<!--                                지체 없이 필요한 조치를 취해야 합니다. 이때 회사는 보호자임을 입증할 수 있는 문서 등의 확인서류 제출을 요청할 수 있습니다.<br/><br/>-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                제10조 [회원정보의 변경]<br/>-->
<!--                                ① 회원은 회사 웹사이트 “정보수정” 페이지에서 언제든지 자신의 개인정보를 열람하고 수정할 수 있습니다.<br/>-->
<!--                                ② 회원이 전항의 변경사항을 수정하지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.<br/><br/>-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                제11조 [“회원”의 “아이디” 및 “비밀번호”의 관리에 대한 의무]<br/>-->
<!--                                ① 회원은 아이디와 비밀번호에 대한 관리책임이 있으며, 이를 타인에게 공개하여 제3자가 이용하도록 하여서는 안됩니다.<br/>-->
<!--                                ② 회원은 자신의 아이디 및 비밀번호가 유출되어 제3자에 의해 사용되고 있음을 인지한 경우, 즉시 회사에 알려야 합니다.<br/>-->
<!--                                ③ 회사는 전항의 경우 회원의 개인정보보호 및 기타 서비스 부정이용행위 방지 등을 위하여 회원에게 비밀번호의 변경 등 필요한 조치를 요구할 수 있으며, 회원은-->
<!--                                회사의 요구가 있는 즉시 회사의 요청에 성실히 응해야 합니다.<br/>-->
<!--                                ④ 회사는 회원이 본조 제2항 및 제3항에 따른 의무를 성실히 이행하지 않아 발생한 불이익에 대하여 책임지지 않습니다.<br/><br/>-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                제12조 [이용계약의 해지·해제 등]<br/>-->
<!--                                ① 회원이 이용계약의 해지 또는 해제를 원할 경우에는 본인이 회사 학습지원센터에 전화접수를 하거나 웹사이트를 통하여 신청할 수 있으며, 회사는 회원의-->
<!--                                의사표시를 수령한 후 지체없이 이러한 사실을 회신하고 본 약관에 따라 환불 등의 조치를 취합니다.<br/>-->
<!--                                ② 회사는 회원이 본 약관 또는 관계법령 등을 위반하는 경우, 이용계약을 해지할 수 있습니다.<br/><br/>-->

<!--                            </p>-->
<!--                            <h2>제3장 서비스 이용</h2>-->
<!--                            <p>-->
<!--                                <br/>-->
<!--                                제13조 [서비스의 원활한 이용]<br/>-->
<!--                                ① 회사는 서비스의 원활한 제공을 위하여 회원이 회사 웹사이트 접속 시, 회사에서 제작 및 배포하는 ActiveX Control의 설치를 권장합니다.<br/>-->
<!--                                ② 회원이 해당 프로그램 설치를 위하여 설치동의 [“예”] 버튼을 클릭하면, 회원 PC에 자동으로 설치됩니다.<br/>-->
<!--                                ③ 회원이 회사에서 권장하는 본조 제1항의 프로그램을 설치하지 않는 경우 일부 서비스 이용에 어려움이 있을 수 있습니다.<br/><br/>-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                제14조 [콘텐츠 이용에 필요한 기술사양]<br/>-->
<!--                                ① 회사가 제공하는 콘텐츠를 이용하는데 필요한 PC의 최소사양은 아래와 같습니다.<br/>-->
<!--                                &emsp;&emsp;- CPU: Pentium4 2.4 이상<br/>-->
<!--                                &emsp;&emsp;- 메모리: 2G 이상<br/>-->
<!--                                &emsp;&emsp;- HDD: C:\ 공간이 20GB이상<br/>-->
<!--                                &emsp;&emsp;- VGA : NVIDIA GeForce FX 5200 128MB 이상 또는 ATI Radeon 9500 128MB 이상<br/>-->
<!--                                &emsp;&emsp;- 운영체제 : Windows 7 이상<br/>-->
<!--                                &emsp;&emsp;- DirectX : 9.0 이상<br/>-->
<!--                                &emsp;&emsp;- Internet Explorer : 9.0 이상 (Chrome 사용권장)<br/>-->
<!--                                ② 회사가 제공하는 콘텐츠를 이용할 수 있는 학습기기의 기술사양은 학습지원센터에 전화로 문의하거나 회사 웹사이트 내에서 확인할 수 있습니다.<br/><br/>-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                제15조 [거래조건에 대한 정보의 표시]<br/>-->
<!--                                ① 회사는 다음 각호에 해당하는 사항을 해당 콘텐츠 또는 그 포장에 표시합니다.<br/>-->
<!--                                &emsp;&emsp;1. 콘텐츠의 명칭, 종류, 내용, 가격, 이용기간<br/>-->
<!--                                &emsp;&emsp;2. 콘텐츠 이용에 필요한 전자매체의 최소한의 기술사양<br/>-->
<!--                                &emsp;&emsp;3. 휴대가 가능한 학습용 전자기기의 사용가능 여부<br/>-->
<!--                                &emsp;&emsp;4. 환불기준 등 서비스 이용계약의 해지방법 및 효과<br/>-->
<!--                                &emsp;&emsp;5. 이용약관 및 개인정보처리방침<br/>-->
<!--                                ② 회사는 전항 각호의 사항을 회사 웹사이트, 본 이용약관, 개인정보처리방침 등에서 이미 고지하고 있는 경우, 별도로 표시하지 않을 수 있습니다.<br/><br/>-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                제16조 [서비스 이용계약의 성립 및 결제방법 등]<br/>-->
<!--                                ① 회원은 회사가 제공하는 다음 각호 또는 이와 유사한 절차에 의하여 콘텐츠 서비스 이용을 신청을 합니다. 회사는 계약 체결 전, 다음 각호의 사항에 관하여-->
<!--                                회원이 정확하게 이해하고 실수 또는 착오 없이 거래할 수 있도록 정보를 제공합니다.<br/>-->
<!--                                &emsp;&emsp;1. 콘텐츠 목록의 열람 및 선택<br/>-->
<!--                                &emsp;&emsp;2. 콘텐츠 상세정보 확인<br/>-->
<!--                                &emsp;&emsp;3. 결제하기 클릭<br/>-->
<!--                                &emsp;&emsp;4. 주문상품 및 결제금액 확인(환불규정 안내)<br/>-->
<!--                                &emsp;&emsp;5. 성명, 주소 등 정보 입력<br/>-->
<!--                                &emsp;&emsp;6. 결제수단 선택<br/>-->
<!--                                &emsp;&emsp;7. 결제금액 재확인<br/>-->
<!--                                &emsp;&emsp;8. 결제<br/>-->
<!--                                ② 회원은 신용카드, 휴대폰 결제 등 회사에서 정하고 있는 방법으로 유료서비스의 결제가 가능합니다. 다만, 각 결제수단마다 결제수단의 특성에 따른 일정한 제한이 있을 수 있습니다.<br/>-->
<!--                                ③ 미성년 회원의 결제는 원칙적으로 보호자의 명의 또는 보호자 동의하에 이루어져야 하고, 보호자는 본인 동의없이 체결된 자녀(미성년자)의 계약을 취소할 수 있습니다.<br/>-->
<!--                                ④ 미성년자가 유료서비스의 대금을 자신의 명의로 결제하는 경우, 당해 미성년자는 보호자의 승낙을 증명하는 문서, 전자우편 등을 제공하거나 유ㆍ무선을 통하여-->
<!--                                확인을 할 수 있도록 보호자의 연락처를 제공하여야 합니다. 만약 이러한 절차를 이행하지 않을 경우 결제금액은 보호자에 의하여 처분이 허락된 재산으로 볼 수-->
<!--                                있습니다.<br/>-->
<!--                                ⑤ 회사는 보호자의 동의 여부를 유ㆍ무선 등의 방법을 통하여 확인할 수 있습니다.<br/>-->
<!--                                ⑥ 회사는 회원의 유료서비스 이용신청이 다음 각호에 해당하는 경우에는 승낙하지 않거나, 그 사유가 해소될 때까지 승낙을 유보할 수 있습니다.<br/>-->
<!--                                &emsp;&emsp;1. 유료 서비스의 이용요금을 납입하지 않는 경우<br/>-->
<!--                                &emsp;&emsp;2. 유료 서비스 신청금액 총액과 입금총액이 일치하지 않은 경우<br/>-->
<!--                                &emsp;&emsp;3. 기타 합리적인 이유가 있는 경우로서 회사가 필요하다고 인정되는 경우<br/>-->
<!--                                ⑦ 회사는 회원이 본조 위 조항의 절차에 따라 유료서비스 이용을 신청할 경우, 승낙의 의사표시로써 본 약관 제7조의 방법을 통하여 회원에게 통지하고, 승낙의-->
<!--                                통지가 회원에게 도달한 시점에 계약이 성립한 것으로 봅니다.<br/>-->
<!--                                ⑧ 회사의 승낙의 의사표시에는 회원의 이용신청에 대한 확인 및 서비스제공 가능여부, 이용신청의 정정, 취소 등에 관한 정보 등을 포함합니다.<br/><br/>-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                제17조 [회사의 의무]<br/>-->
<!--                                ① 회사는 법령과 본 약관이 정하는 권리의 행사와 의무의 이행을 신의에 좇아 성실하게 하여야 합니다.<br/>-->
<!--                                ② 회사는 회원이 서비스를 이용하는 과정에서 회원 개인정보가 외부로 유출되지 않도록 방화벽을 설치하는 등 별도의 보안장치를 사용하고 있으며, 그 구체적인-->
<!--                                내용은 회사 웹사이트의 “개인정보처리방침”에서 확인할 수 있습니다.<br/>-->
<!--                                ③ 회사는 회원이 유료서비스 이용 및 그 대금내역을 수시로 확인할 수 있도록 조치합니다.<br/>-->
<!--                                ④ 회사는 서비스 이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 지체없이 처리합니다. 회원이 제기한 의견이나 불만사항에-->
<!--                                대해서는 게시판을 활용하거나 전자우편 등을 통하여 그 처리과정 및 결과를 전달합니다.<br/>-->
<!--                                ⑤ 회사는 본 약관에서 정한 회사의 의무 위반으로 인하여 회원이 입은 손해를 배상합니다.<br/><br/>-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                제18조 [회원의 의무]<br/>-->
<!--                                ① 회사의 명시적 동의가 없는 한 회원의 이용권한은 회원 개인에 한정되며, 타인에게 양도, 증여하거나 이를 담보 등으로 제공할 수 없습니다.<br/>-->
<!--                                ② 회사는 회원이 다음 각호의 1에 해당하는 의무를 위반 하였을 경우, 기간을 정하여 전부 또는 일부의 서비스 이용을 중지하거나 상당기간의 최고 후 이용계약을-->
<!--                                해지할 수 있습니다.<br/>-->
<!--                                &emsp;&emsp;1. 회원이 이용신청 또는 변경 시, 허위사실을 기재하거나 자신 또는 다른 회원의 ID 및 개인정보를-->
<!--                                이용ㆍ공유하는 경우<br/>-->
<!--                                &emsp;&emsp;2. 회원이 회사 또는 제3자의 권리 등을 침해하는 경우<br/>-->
<!--                                &emsp;&emsp;3. 회원이 회사에서 제공하는 서비스를 이용하여 상품 또는 용역을 판매하는 영업활동 등의 상행위를 하는 경우<br/>-->
<!--                                &emsp;&emsp;4. 회원이 다른 이용자의 서비스 이용을 방해하거나 회사의 운영진, 직원 또는 관계자를 사칭하는 경우<br/>-->
<!--                                &emsp;&emsp;5. 회원이 회사 웹사이트 내에서 사회의 안녕질서 혹은 미풍양속을 저해하는 행위를 하거나, 그와-->
<!--                                관련된 부호ㆍ문자ㆍ음성ㆍ음향 및 영상 등의 정보를 게시하여 타인에게 유포시키는 경우<br/>-->
<!--                                &emsp;&emsp;6. 회원이 회사로부터 유ㆍ무상으로 제공받은 캐쉬 등의 재화를 제3자와 유ㆍ무상 등의 방법으로 거래하는 경우<br/>-->
<!--                                &emsp;&emsp;7. 회사의 서비스 운영을 고의로 방해 하거나 그리할 목적으로 다량의 정보를 전송하거나 광고성 정보를-->
<!--                                전송하는 경우<br/>-->
<!--                                &emsp;&emsp;8. 관계법령 및 기타 본 약관에서 규정한 사항을 위반한 경우<br/>-->
<!--                                &emsp;&emsp;9. 회사 및 타인의 명예를 훼손하거나 모욕하는 경우 등<br/><br/>-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                제19조 [부정이용 금지 및 차단]<br/>-->
<!--                                ① 회사는 다음 각호에 해당하는 경우를 부정 이용행위로 봅니다.<br/>-->
<!--                                &emsp;&emsp;1. 동일한 ID로 2대 이상의 PC에서 동시접속이 발생하는 경우<br/>-->
<!--                                &emsp;&emsp;2. 동일한 ID로 다수의 PC 또는 IP에서 서비스를 이용하는 경우<br/>-->
<!--                                &emsp;&emsp;3. 자신의 ID 및 강좌 등의 서비스를 타인이 이용하도록 하는 경우<br/>-->
<!--                                &emsp;&emsp;4. 자신의 ID 및 강좌 등의 서비스를 타인에게 판매, 대여, 양도하는 행위 및 이를 광고하는 행위<br/>-->
<!--                                &emsp;&emsp;5. 서비스 이용 중 복제프로그램을 실행하는 경우 또는 녹화를 하거나 시도하는 경우<br/>-->
<!--                                ② 회사는 전항에 따른 부정 이용자가 발견 되었을 경우, 다음 각호에 따른 조치를 취할 수 있습니다.<br/>-->
<!--                                &emsp;&emsp;1. [1차 발견 시] - 전자우편, 쪽지, 팝업창을 통하여 경고합니다.<br/>-->
<!--                                &emsp;&emsp;2. [2차 발견 시] - 전호와 동일한 방법으로 경고하고 동시에 서비스 제공을 정지합니다. 이때, 보호자가-->
<!--                                가족관계를 확인할 수 있는 주민등록등본을 첨부하여 “재발방지확약ㆍ보증서”를 회사에 송달하는 경우에는-->
<!--                                서비스 제공을 재개합니다.<br/>-->
<!--                                &emsp;&emsp;3. [3차 발견 시] - 전자우편, 쪽지, 팝업창 또는 유ㆍ무선을 통하여 3차 위반내용의 통지 및 서비스 제공을-->
<!--                                정지하고 30일간의 소명기간 부여하며, 정당한 사유가 없는 경우 강제 탈퇴처리 합니다.<br/>-->
<!--                                ③ 회원은 전항 제2호 및 3호의 조치를 이유로 서비스 이용기간의 연장을 요구할 수 없습니다.<br/>-->
<!--                                ④ 회원은 회사로부터의 본조 제2항의 조치에 이의가 있는 경우 회사 학습지원센터 또는 부정사용관리센터에 해당 사실에 대하여 소명할 수 있으며, 회원이 자신의-->
<!--                                고의나 과실이 없었음을 입증한 경우 회사는 서비스 제공 정지기간만큼 이용기간을 연장합니다.<br/>-->
<!--                                ⑤ 부정이용 식별방법 및 차단<br/>-->
<!--                                &emsp;&emsp;1. 회사는 회원의 서비스 이용 중에 수집ㆍ확인된 IP정보 등의 자료를 토대로 서버를 통하여 부정이용-->
<!--                                여부를 분류ㆍ확인합니다.<br/>-->
<!--                                &emsp;&emsp;2. 회사는 이용자가 서비스 이용 중에 복제프로그램을 실행시키거나 동일한 ID로 동시 접속을 하는 경우-->
<!--                                서비스 이용 접속을 강제로 차단합니다.<br/><br/>-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                제20조 [서비스의 제공 및 변경]<br/>-->
<!--                                ① 회사는 연중무휴, 1일 24시간 서비스 제공을 원칙으로 합니다.<br/>-->
<!--                                ② 회사는 운영상 또는 기술상 등의 상당한 이유가 있는 경우 제공하고 있는 서비스를 변경할 수 있습니다.<br/>-->
<!--                                ③ 전항에 따라 서비스가 변경되는 경우에는 변경되는 사유 및 내용을 본 약관 제7조에 따른 방법으로 회원에게 통지합니다.<br/><br/>-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                제21조 [정보의 제공 및 광고의 게재 등]<br/>-->
<!--                                ① 회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편 또는 유선상 등의 방법으로 회원에게 제공할 수 있습니다. 다만,-->
<!--                                회원은 언제든지 전자우편 등을 통하여 수신 거절을 할 수 있습니다.<br/>-->
<!--                                ② 회사는 서비스 제공과 관련하여 해당 서비스 화면, 회사 웹사이트, 회원가입 시 회원이 직접 작성한 전자우편 등에 광고를 게재할 수 있습니다. 광고가 게재된-->
<!--                                전자우편 등을 수신한 회원은 수신거절을 할 수 있습니다.<br/>-->
<!--                                ③ 회사는 회원에게 회사가 서비스하는 상품에 대하여 회원 가입 시 기입한 전화번호로 전화권유판매를 할 수 있으며, 회원은 온라인 홈페이지 또는 학습지원센터-->
<!--                                등을 통하여 회사의 전화권유판매에 대한 수신거절을 할 수 있습니다.<br/><br/>-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                제22조 [회원이 등록한 게시물의 이용 및 삭제]<br/>-->
<!--                                ① 회사는 회원이 등록한 게시물 중 본 약관 및 관계법령 등에 위배되는 게시물이 있는 경우 이를 지체없이 삭제합니다.<br/>-->
<!--                                ② 회사가 운영하는 게시판 등에 게시된 정보로 인하여 법률상 이익이 침해된 자는 회사에게 당해 정보의 삭제 또는 반박내용의 게재를 요청할 수 있습니다. 이-->
<!--                                경우 회사는 지체없이 필요한 조치를 취하고 이를 즉시 신청인에게 통지합니다.<br/>-->
<!--                                ③ 회사는 회사가 제공하는 웹사이트에 회원이 게시한 게시물을 이용·수정하여 마케팅 및 출판 등에 활용할 수 있습니다.<br/>-->
<!--                                ④ 회원은 전항에 따른 회사의 이용 등에 대하여 웹사이트 등을 통하여 철회 할 수 있으며, 회사는 회원의 철회의사를 받은 후로부터 해당 회원의 게시물을-->
<!--                                사용하지 않습니다. 단, 회사는 철회의 의사표시 전 기 제작된 제작물에 대하여는 소진 시 까지 사용할 수 있습니다.<br/><br/>-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                제23조 [저작권]<br/>-->
<!--                                ① 회사가 제공하는 모든 콘텐츠에 대한 저작권은 회사에 있습니다.<br/>-->
<!--                                ② 회원은 회사가 제공하는 서비스를 이용함으로써 얻은 정보를 회사의 사전 승낙없이 녹화ㆍ복제ㆍ편집ㆍ전시ㆍ전송ㆍ배포ㆍ판매ㆍ방송ㆍ공연하는 등의 행위로 회사의-->
<!--                                저작권을 침해 하여서는 안됩니다.<br/><br/>-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                제24조 [개인정보보호]<br/>-->
<!--                                ① 회사는 회원의 개인정보보호를 중요시하며, 회원이 회사의 서비스를 이용함과 동시에 온라인상에서 회사에게 제공한 개인정보의 철저한 보호를 위하여 최선을 다하고-->
<!--                                있습니다.<br/>-->
<!--                                ② 개인정보보호와 관련된 자세한 사항은 회사 웹사이트에서 전자적 표시형태로 제공되는 개인정보처리방침에서 확인하실 수 있습니다.<br/><br/>-->

<!--                            </p>-->
<!--                            <h2>제 4 장 청약철회 및 환불</h2>-->
<!--                            <p>-->
<!--                                <br/>-->
<!--                                제26조 [관계법령상 청약철회 및 환불정책]<br/>-->
<!--                                ① 회사의 본 약관상 청약철회 및 환불규정 등은 “전자상거래 등에서의 소비자보호에 관한 법률”, “콘텐츠 산업 진흥법” 등을 반영하였습니다.<br/>-->
<!--                                ② 회사는 회원에게 대금 등을 환불할 때에 기 지불한 대금의 결제와 동일한 방법으로 전부 또는 일부를 환불합니다. 다만, 동일한 방법으로 환불이 불가능할 때에는 이를 사전에 고지합니다.<br/>-->
<!--                                ③ 회사는 회원에게 강의 구매 진행한 후 예외적인 상황을 제외하고는 환불이 불가 합니다.<br/><br/>-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                제27조 [청약철회]<br/>-->
<!--                                ① 교재 및 학습기기와 같은 재화를 공급받은 날로부터 7일 이내에는 청약철회가 가능합니다. 다만, 재화 등의 내용이 회사가 표시·광고한 내용과 다르거나-->
<!--                                계약내용과 다르게 이행된 경우에는 그 재화 등을 공급받은 날부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회가-->
<!--                                가능합니다.<br/>-->
<!--                                ② 회원이 재화 등의 청약철회를 하는 경우 회사에 공급받은 재화를 반환하여야 하며, 회사는 재화 등을 반환받은 날부터 3영업일 이내에 이미 지급받은 대금을-->
<!--                                환불합니다.<br/>-->
<!--                                ③ 청약철회 시 재화 등이 일부 소비된 경우에는 해당 금액을 공제하고 환불하며, 반환에 필요한 비용은 회원이 부담해야 합니다.<br/><br/>-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                제28조 [온라인 강좌의 환불 및 변경]<br/>-->
<!--                                ① 회사는 온라인 강좌 등에 대해 원칙적으로 다음과 같은 환불규정을 적용합니다.<br/>-->

<!--                                ② 구분 반환사유 발생일 반환금액<br/>-->
<!--                                &emsp;- 회사의 교습정지, 자진폐강, 등록말소 등 교습을 할 수 없거나 교습장소를 제공할 수 없게 된 날 이미 납부한 교습비 등을 일할 계산한 금액<br/>-->
<!--                                &emsp;- 회원이 본인의 의사로 수강을 포기하는 경우는 환불이 불가 합니다.<br/>-->
<!--                                &emsp;- 단일 강의의 경우는 결제후 수강을 하지 않더라도 환불이 불가 합니다.<br/>-->
<!--                                &emsp;- 클래스강의(여러개의 강의) 경우<br/>-->
<!--                                &emsp;&emsp;총 교습시간의 1/3 경과 전 이미 납부한 교습비 등의 2/3에 해당하는 금액<br/>-->
<!--                                &emsp;&emsp;총 교습시간의 1/2 경과 전 이미 납부한 교습비 등의 1/2에 해당하는 금액<br/>-->
<!--                                &emsp;&emsp;총 교습시간의 1/2 경과 후 반환하지 않음<br/>-->
<!--                                &emsp;- 클래스강의 경우는 구매후 1개월 지난 후에는 환불이 불가 합니다.<br/>-->

<!--                                총 교습시간은 교습기간 중의 총 교습시간을 말하며, 반환금액의 산정은 반환사유가 발생한 날까지 경과된 교습시간을 기준으로 한다.<br/>-->

<!--                                ③ 회원이 환불을 하고자 하는 경우 회사 웹사이트 또는 학습지원센터에 전화 등을 통하여 회사에 그 의사를 표시하여야 하며, 회사는 환불요청을 접수하고 회원의-->
<!--                                요청과 환불규정을 확인한 후, 5영업일 이내에 교습비를 환불합니다. 단, 회사의 책임영역외의 금융사의 전산마비 등 외부적 사정 의하여 다소 지연될 수 있으며,-->
<!--                                이와 같은 지연의 경우 회사는 책임지지 않습니다.<br/>-->
<!--                                ④ 기간제 정액상품, 이벤트성 강좌, 비정규 강좌, 특별기획 강좌 등의 상품은 별도의 수강 취소, 변경 및 환불규정이 적용될 수 있으며, 자세한 내용은-->
<!--                                학습지원센터에 전화로 문의하거나 회사 웹사이트 내에서 확인할 수 있습니다.<br/>-->
<!--                                ⑤ 학습기기로 강의를 저장한 경우, 이는 실제 수강한 것으로 간주되어 환불 시 저장한 부분만큼 공제됩니다. 환불 시에는 기기 내 저장되어 있던 강의를-->
<!--                                삭제하여야 하며, 기기의 분실, 고장 등으로 인하여 강의의 삭제가 불가능할 경우에는 별도의 환불규정이 적용될 수 있습니다.<br/><br/>-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                제29조 [사용시간 구매 및 환불]<br/>-->
<!--                                ① 회사가 무료로 회원에게 지급한 사이버머니로 사용시간을 자동결제 및 구매한 경우 환불이 안됩니다.-->
<!--                                구매한 사용시간은 유효기간은 회사정책에 의한 기준을 따르며, 그 기간 이후에는 자동으로 소멸됩니다.<br/>-->
<!--                                ② 사용시간을 유료로 구매한 경우에도 환불이 안됩니다.<br/>-->
<!--                                ③ 사용시간은 사용하고 있는 강의및 사용자의 선택따라 성능차이가 있을수 있으며, 성능따른 구매시간의 차감률이 다를수 있으며, 오차범위가 있을수 있습니다.<br/>-->
<!--                                ④ 전항에 해당하지 않는 교환, 환불의 경우 반환에 필요한 비용은 회원이 부담합니다.<br/>-->
<!--                                ⑤ 환불, 반품 등 기타 사항에 대하여 회사를 통하여 보다 자세하게 알 수 있습니다.<br/><br/>-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                제30조 [사이버머니 충전 및 환불]<br/>-->
<!--                                ① 회사가 무료로 회원에게 지급한 사이버머니(코인 등 명칭불문)는 유효기간 내에만 사용이 가능하며 환불은 되지 않습니다. 사이버머니의-->
<!--                                유효기간은 회사정책에 의한 기준을 따르며, 그 기간 이후에는 자동으로 소멸됩니다. 이에 관한 자세한 내용은 회사 웹사이트 내에서 확인할 수 있습니다.<br/>-->
<!--                                ② 회원은 회사가 제안한 결제수단을 이용하여 사이트에서 유료로 사이버머니(코인 등 명칭불문)를 충전할 수 없으며, 사용하지 않은 사이버머니 잔액에 대해서는 환불을 요청할 수 없습니다.<br/>-->
<!--                                ③ 회원은 사이버머니 적립에 오류가 있거나 전부 또는 일부가 삭제된 경우, 회사에 이의신청을 할 수 있습니다. 회사는 회원의 이의신청에 따라 그 내역을 확인한-->
<!--                                후 그 결과를 회원에게 통지하고, 이의신청이 정당한 경우 즉시 사이버머니의 복구 등의 조치를 취합니다.<br/>-->
<!--                                ④ 회사는 경영상·기술상의 이유로 사이버머니 서비스를 종료하는 경우 최소 30일 전까지 회원에게 이 사실을 고지하며, 이 기간 내에 사용하지 못한 사이버머니에-->
<!--                                대해서는 사전에 정한 보상방법에 따라 회원에게 보상할 수 있습니다.<br/><br/>-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                제31조 [과오금의 환급]<br/>-->
<!--                                ① 회원이 교습비 및 대금 등을 결제함에 있어서 과오금을 지급한 경우 회사는 대금결제와 동일한 방법으로 과오금을 환불합니다. 다만, 동일한 방법으로 과오금의-->
<!--                                환불이 불가능할 때에는 즉시 이를 고지하고, 회원이 선택한 방법으로 환불합니다.<br/>-->
<!--                                ② 회사의 책임있는 사유로 과오금이 발생한 경우 회사는 계약비용·수수료 등에 관계없이 과오금 전액을 환불합니다. 다만, 회원의 책임있는 사유로 과오금이 발생한-->
<!--                                경우 회사는 과오금을 환불하는데 소요되는 비용을 합리적인 범위 내에서 공제하고 환불할 수 있습니다.<br/><br/>-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                제32조 [서비스 하자 등에 대한 피해보상]<br/>-->
<!--                                ① 회사는 서비스의 중지·장애가 발생하는 경우 서비스 중지, 장애시점을 기준으로 24시간 이내에 회원에게 사전고지 합니다. 다만, 회원의 책임있는 사유로-->
<!--                                인하여 서비스가 중지되거나 장애가 발생한 경우 서비스 중지·장애시간에 포함되지 않습니다.<br/>-->
<!--                                ② 회사가 서비스의 중지·장애에 대하여 사전고지한 경우에 있어서 회원의 피해구제 등은 다음 각호에 의합니다. 다만, 서비스 개선을 목적으로 한 설비 점검 및-->
<!--                                보수 시 1개월을 기준으로 최대 24시간은 중지·장애 시간에 포함하지 않습니다.<br/>-->
<!--                                &emsp;&emsp;1. 1개월을 기준으로 서비스 중지·장애시간이 10시간을 초과하는 경우: 10시간과 이를 초과한 시간의-->
<!--                                2배의 시간만큼 이용기간을 무료로 연장<br/>-->
<!--                                &emsp;&emsp;2. 1개월을 기준으로 서비스 중지·장애시간이 10시간을 초과하지 않은 경우: 중지·장애시간에 해당하는-->
<!--                                시간을 무료로 연장<br/>-->
<!--                                ③ 회사가 서비스의 중지·장애에 대하여 사전고지 하지 않은 경우에 있어서 회원의 피해구제 등은 다음 각호에 의합니다.<br/>-->
<!--                                &emsp;&emsp;1. 1개월 동안의 서비스 중지·장애발생 누적시간이 72시간을 초과한 경우: 계약해제 또는 해지 및-->
<!--                                미이용기간을 포함한 잔여기간에 대한 이용대금 환불 및 손해배상(단, 회사가 고의 또는 과실없음을 입증한 경우-->
<!--                                손해배상 책임을 지지 않습니다)<br/>-->
<!--                                &emsp;&emsp;2. 회사의 책임있는 사유로 인한 서비스 중지 또는 장애의 경우: 서비스 중지·장애시간의 3배를 무료로 연장<br/>-->
<!--                                &emsp;&emsp;3. 불가항력 또는 제3자의 불법행위 등으로 인해 서비스의 중지 또는 장애가 발생한 경우: 계약을-->
<!--                                해제할 수는 없지만 서비스의 중지 또는 장애시간만큼 무료로 이용기간을 연장<br/><br/>-->
<!--                            </p>-->

<!--                            <h2>제 5 장 기타</h2>-->
<!--                            <p>-->
<!--                                <br/>-->
<!--                                제33조 [면책조항]<br/>-->
<!--                                ① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.<br/>-->
<!--                                ② 회사는 회원의 귀책사유로 인하여 발생한 서비스 이용의 장애에 대하여는 책임을 지지 않습니다.<br/>-->
<!--                                ③ 회사는 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.<br/>-->
<!--                                ④ 회사는 이용자 상호간 또는 이용자와 제3자 간에 콘텐츠를 매개로 하여 발생한 분쟁 등에 대하여 책임을 지지 않습니다.<br/><br/>-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                제34조 [분쟁의 해결 등]<br/>-->
<!--                                ① 회사와 이용자 간의 콘텐츠 거래 또는 이용에 관한 분쟁은 콘텐츠분쟁조정위원회를 통해 조정할 수 있습니다.<br/>-->
<!--                                ② 회사와 이용자 간에 발생한 소송의 관할은 제소 당시 이용자의 주소 또는 거소를 관할하는 법원을 전속관할로 합니다. 단, 제소 당시 이용자의 주소 또는-->
<!--                                거소가 불분명한 경우에는 민사소송법에 따릅니다.<br/><br/>-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                [부칙]-->
<!--                                1.본 약관은 2021년 05월 01일부터 시행합니다.-->
<!--                            </p>-->
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card flat>
                        <v-card-text>
                            <vue-markdown v-html="$t('provision.privacy')"></vue-markdown>

<!--                            <p>-->
<!--                                주식회사 유엔진솔루션즈'(www.msaez.io 이하 "회사"라 함)는 개인정보보호법,통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호-->
<!--                                등에 관한 법률 등을 준수하고 있습니다.-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                회사는 개인정보처리방침을 통하여 이용자가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지-->
<!--                                알려드립니다.-->

<!--                                주식회사 유엔진솔루션즈의 개인정보처리방침의 주요내용은 다음과 같습니다.<br/>-->

<!--                                1. 개인정보의 처리 목적<br/>-->
<!--                                2. 처리하는 개인정보의 항목<br/>-->
<!--                                3. 개인정보의 처리 및 보유기간<br/>-->
<!--                                4. 개인정보의 제3자 제공<br/>-->
<!--                                5. 개인정보의 위탁<br/>-->
<!--                                6. 이용자와 법정대리인의 권리 및 그 행사방법<br/>-->
<!--                                7. 개인정보의 파기<br/>-->
<!--                                8. 개인정보 자동수집 장치의 설치·운영 및 그 거부에 관한 사항<br/>-->
<!--                                9. 스마트폰 앱 관리<br/>-->
<!--                                10. 개인정보보호책임자<br/>-->
<!--                                11. 개인정보의 안전성 확보조치<br/>-->
<!--                                12. 개인정보처리방침 변경에 관한 사항<br/>-->
<!--                                13. 개인정보에 관한 민원서비스<br/>-->
<!--                                <br/>-->
<!--                                ο 본 방침은 2021년 5월 1일부터 시행됩니다.-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                1. 개인정보의 처리 목적-->

<!--                                회사는 다음의 목적을 위해 이용자의 개인정보를 처리합니다.-->

<!--                                ο 서비스 제공에 관한 계약 이행 및 서비스 제공-->
<!--                                - 콘텐츠 제공, 구매 및 요금 결제, 본인인증, 고객상담-->

<!--                                ο 회원 관리-->
<!--                                회원제 서비스 이용에 따른 본인확인, 개인 식별, 불량회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 연령확인, 불만처리 등 민원처리,-->
<!--                                고지사항전달-->

<!--                                ο 마케팅 및 광고에 활용-->
<!--                                신규 서비스(제품) 개발 및 특화, 이벤트 등 광고성 정보 전달, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 접속 빈도 파악 또는 회원의 서비스-->
<!--                                이용에 대한 통계-->

<!--                                ο 기타-->
<!--                                가입횟수 제한, 분쟁조정을 위한 기록보존, 회원의 각종 합격유무 확인 및 기타 통계자료 산출-->

<!--                            </p>-->
<!--                            <p>-->
<!--                                2. 처리하는 개인정보의 항목-->

<!--                                가. 회사는 회원가입, 고객상담, 각종 서비스 제공을 위해 다음과 같이 최소한의 개인정보를 수집 또는 처리하고 있습니다.-->
<!--                                ο 필수항목-->
<!--                                - 이름, 생년월일, 아이디, 비밀번호,이메일 주소, 본인인증정보(휴대전화번호 또는 이메일 주소 중 선택)-->
<!--                                - 만 14세 미만인 경우 법정대리인 정보(이름, 휴대폰번호)-->
<!--                                ο 선택항목-->
<!--                                - 성별, 전화번호-->

<!--                                나. 서비스 이용과정이나 사업 처리과정에서 아래와 같은 정보들이 생성되어 수집될 수 있습니다.-->
<!--                                ο 결제기록 : 승인번호, 은행/카드사 코드-->
<!--                                ο 개인정보 : 홈페이지(회원가입, 게시판 등), 서면양식, 전화/팩스를 통한 회원가입, 경품 행사 응모, 배송 요청-->
<!--                                ο 전화를 통한 개인정보 수집 시에는 통화내용이 녹취가 되며, 녹취사실을 이용자에게 알려드립니다.-->


<!--                                ※ 정보주체의 수집이용 거부권 및 불이익 고지-->
<!--                                정보주체는 회사가 수집하는 개인정보의 항목 및 수집 방법에 동의를 거부할 수 있으며, 거부 시 회원에게 제공되는 서비스 이용의 일부가 제한 될 수 있습니다.-->

<!--                            </p>-->
<!--                            <p>-->
<!--                                3. 개인정보의 처리 및 보유기간-->

<!--                                개인정보의 처리 및 보유기간은 다음과 같습니다.-->

<!--                                ο 보유항목: 처리항목과 같습니다.-->
<!--                                ο 보유근거: 이용약관 및 개인정보처리방침-->
<!--                                ο 보유기간: 이용계약에 따른 회원자격이 유지되는 기간동안-->

<!--                                그러나 회원탈퇴 후에도 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 일정 기간동안 회원정보를 보관합니다.-->

<!--                                ο 표시/광고에 관한 기록 : 6개월 (전자상거래등에서의 소비자보호에 관한 법률)-->
<!--                                ο 계약 또는 청약철회 등에 관한 기록 : 5년 (전자상거래 등에서의 소비자보호에 관한 법률)-->
<!--                                ο 대금결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한 법률)-->
<!--                                ο 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래등에서의 소비자보호에 관한 법률)-->
<!--                                ο 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년 (신용정보의 이용 및 보호에 관한 법률)-->
<!--                                ο 통신비밀보호법에 따른 통신사실확인자료 : 3개월 (통신비밀보호법)-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                4. 개인정보의 제3자 제공-->

<!--                                회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.-->
<!--                                ο 이용자들이 사전에 동의한 경우 (개인정보의 제공업체명, 제공항목, 보유기간은 별도 동의 시에 고지)-->
<!--                                ο 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우-->

<!--                            </p>-->
<!--                            <p>-->
<!--                                5. 개인정보의 위탁-->

<!--                                회사는 서비스 이행을 위해 아래와 같이 외부 전문업체에 개인정보를 위탁하여 운영하고 있습니다.-->

<!--                                가. 이용자와 법정대리인은 언제든지 다음 각 호의 사항에 관하여 개인정보 열람·정정을 요구할 수 있습니다.-->
<!--                                ο 회사가 보유하고 있는 이용자의 개인정보-->
<!--                                ο 회사가 이용자의 개인정보를 이용하거나 제3자에게 제공한 내역-->
<!--                                ο 회사에게 개인정보수집ㆍ이용ㆍ제공 등의 동의를 한 내역-->

<!--                                이용자는 회사 웹사이트의 [마이페이지] - [회원정보] - [회원정보관리] 메뉴에서 직접 자신의 정보를 열람, 정정을 할 수-->
<!--                                있으며, 별도로 개인정보보호책임자에게 서면, 전화, 전자우편 등을 통하여 개인정보의 열람, 정정을 요청할 수 있습니다.-->

<!--                                나. 이용자와 법정대리인은 언제든지 자신의 개인정보처리의 정지를 요구할 수 있습니다.-->

<!--                                다. 이용자와 법정대리인은 언제든지 회원가입 시 개인정보의 수집, 이용, 제공 등에 대해 동의하신 의사표시를 철회-->
<!--                                (회원탈퇴)할 수 있습니다.-->

<!--                                동의철회(회원탈퇴)는 회사 웹사이트의 [마이페이지] - [회원정보] - [회원탈퇴] 메뉴에서 본인 확인 절차를 거친 후 직접 동의철회(회원탈퇴)를 하거나,-->
<!--                                별도로 개인정보보호책임자에게 서면, 전화 또는 전자우편 등을 통하여 연락하면 지체 없이 이용자의 개인정보를 파기하는 등 필요한 조치를 취하겠습니다. 단,-->
<!--                                동의철회(회원탈퇴)가 있더라도 관계법령에 따라 최소한의 정보가 보관됩니다.-->

<!--                            </p>-->
<!--                            <p>-->
<!--                                6. 개인정보의 파기-->

<!--                                회사는 회원의 개인정보 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그-->
<!--                                개인정보가 불필요하게 되었을 때에는 그 불필요하게 된 날로부터 5일 이내 개인정보를 파기합니다. 단, 관계법령에 의하여 일정기간 보존하여야할 개인정보는-->
<!--                                일정기간 보존 후에 지체없이 파기됩니다. 파기절차 및 방법은 다음과 같습니다.-->

<!--                                ο 파기절차-->
<!--                                회원의 개인정보 보유기간이 경과되더라도 관계법령에 의하여 일정기간 보존하여야할 개인정보는 별도 DB로 옮겨 일정기간 보존 후 파기합니다. (문서의 경우 별도의-->
<!--                                시건장치가 되어있는 보관함에 보관) 별도 DB로 옮겨진 개인정보는 법령에 의한 보유목적 이외의 다른 목적으로 이용되지 않습니다.-->

<!--                                ο 파기방법-->
<!--                                - 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.-->
<!--                                - 문서로 출력된 개인정보는 파쇄기로 파쇄하거나소각을 통하여 파기합니다.-->

<!--                                ο 개인정보 유효기간제 도입-->
<!--                                - 1년 동안 이용(로그인)이 없는 회원은 휴면으로 분류하여 개인정보를 별도의 DB에 분리, 저장, 관리합니다.-->
<!--                                - 휴면처리 30일 전 이메일 등을 통해 해당 회원에게 관련 내용을 공지합니다.-->
<!--                                - 휴면전환 후 분리보관된 개인정보는 최대 2년동안 보유하며, 해당 기간이 종료되면 즉시 파기합니다.-->
<!--                                - 개인정보 파기 30일 이전 이메일 등을 통해 해당회원에게 관련 내용을 통지합니다.-->
<!--                                - 개인정보 파기 전에 휴면상태인 회원이 서비스 이용 재개를 원하는 경우 휴면상태는 해지되고 분리보관된 개인정보는 복원됩니다.-->
<!--                                - 개인정보 파기 후 동일한 계정 사용은 불가능하며 서비스 재개를 원할 경우 신규가입해야 합니다.-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                7. 개인정보 자동수집 장치의 설치·운영 및 그 거부에 관한 사항-->

<!--                                회사는 이용자의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 와 ‘콜러스플레이어(Kollus Player)’ 등을 운용합니다. 쿠키란 회사의-->
<!--                                웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장됩니다. 또, 콜러스플레이어란-->
<!--                                Media Delivery 시스템으로써, 미디어 자산의 불법적인 녹화, 외부 유출, 화면 캡처 시도, 동일 아이디 중복 재생을 원천적으로 차단하는 서비스로서-->
<!--                                녹화시도 소프트웨어, 이용자 ID, 녹화시도 일시, 콘텐츠명이 서버에 저장됩니다. 회사는 다음과 같은 목적을 위해 쿠키 및 콜러스플레이어를 사용합니다.-->

<!--                                ▶쿠키 등 사용 목적-->
<!--                                이용자의 접속 빈도나 방문 시간 등을 분석하고, 이용자의 취향과 관심분야를 파악하여 개인형 맞춤 이벤트 및 서비스를 제공합니다. 그러나, 쿠키 설치에 대한-->
<!--                                선택권은 이용자가 가지고 있기 때문에 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의-->
<!--                                저장을 거부할 수도 있습니다.-->

<!--                                ▶ 콜러스플레이어 사용 목적-->
<!--                                콜러스플레이어로 수집된 정보는 부정사용을 방지하고, 원격지원이 필요한 사용자 환경의 장애를 해결하는데사용 됩니다. 즉, 동영상강의를 이용하는 사용자의-->
<!--                                학습기기에서 녹화시도가 감지되면 플레이어와 녹화기를 강제로 종료시켜서 동영상 유출을 막고, 녹화를 시도한 사용자의 로그기록을 남겨 재발방지 활동에 근거로-->
<!--                                사용합니다. 뿐만 아니라, 동일 아이디 중복 재생을 차단함으로써 부정사용으로 인한 추가 피해를 막는데도 사용합니다.-->

<!--                                ▶ 쿠키 설정 거부 방법-->
<!--                                예: 쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든-->
<!--                                쿠키의 저장을 거부할 수 있습니다.-->
<!--                                설정방법 예(인터넷 익스플로어의 경우)-->
<!--                                : 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보-->

<!--                                단, 이용자가 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.-->

<!--                            </p>-->
<!--                            <p>-->
<!--                                8. 스마트폰 앱 관리-->

<!--                                스마트폰 앱을 통해 서비스 이용 시, 이용자에게 개인정보 수집 및 이용 동의를 받은 범위 내에서 단말기정보 등에 접근한다는 것을 고지하고 승인을 얻습니다.-->
<!--                                서비스 제공을 위한 스마트폰 앱의 접근권한은 필수 또는 선택으로 요청하고 있으며, 단말기 내 "설정" 메뉴를 통하여 이용자가 직접 권한을 변경할 수 있습니다.-->
<!--                                앱 권한에 대한 자세한 사항은 애플리케이션 스토어를 통해 확인하실 수 있습니다.-->

<!--                                스마트폰 상에서 앱을 삭제하더라도 이용자의 회원계정은 유지되므로, 회원탈퇴를 원하실 경우 PC 웹페이지 상에서 “회원탈퇴” 기능을 이용하시거나-->
<!--                                학습지원센터(1599-1010)로 연락하여 주시기 바랍니다.-->

<!--                            </p>-->
<!--                            <p>-->
<!--                                9. 개인정보보호책임자-->

<!--                                회사는 회원의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인정보보호책임자를 지정하고 있습니다.-->

<!--                                개인정보보호책임자 : 고등이러닝사업본부장 이장훈-->
<!--                                개인정보보호담당자 : 고등이러닝사업본부 마케팅전략실장 이승철-->
<!--                                전화번호 : 1599-1010-->
<!--                                이메일 : keeper@megastudy.net-->

<!--                                이용자는 회사의 서비스를 이용하며 발생하는 모든 개인정보보호 관련 민원을 개인정보보호책임자 혹은 담당부서로 신고할 수 있습니다. 회사는 이용자들의 신고사항에-->
<!--                                대해 신속하게 충분한 답변을 드릴 것입니다.-->

<!--                            </p>-->
<!--                            <p>-->
<!--                                10. 개인정보의 안전성 확보조치-->

<!--                                회사는 「개인정보보호법」 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적, 관리적, 물리적 조치를 하고 있습니다.-->

<!--                                ο 내부관리계획의 수립 및 시행-->
<!--                                회사는 내부관리지침을 수립하여 관리계획을 시행하고 있습니다.-->

<!--                                ο 개인정보 취급 담당자의 최소화 및 교육-->
<!--                                개인정보를 취급하는 담당자를 지정하고 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다.-->

<!--                                ο 개인정보에 대한 접근 제한-->
<!--                                개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며-->
<!--                                침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.-->

<!--                                ο 접속기록의 보관 및 위변조 방지-->
<!--                                개인정보처리시스템에 접속한 기록(웹 로그, 요약정보 등)을 최소 2년 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능을-->
<!--                                사용하고 있습니다.-->

<!--                                ο 개인정보의 암호화-->
<!--                                이용자의 개인정보는 암호화 되어 저장 및 관리되고 있습니다. 또한 중요한 데이터는 저장 및 전송 시 암호화하여 사용하는 등의 별도 보안기능을 사용하고-->
<!--                                있습니다.-->

<!--                                ο 해킹 등에 대비한 기술적 대책-->
<!--                                회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된-->
<!--                                구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다. 또한 네트워크 트래픽의 통제(Monitoring)는 물론 불법적으로 정보를 변경하는-->
<!--                                등의 시도를 탐지하고 있습니다.-->

<!--                                ο 비인가자에 대한 출입 통제-->
<!--                                개인정보를 보관하고 있는 개인정보시스템의 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.-->

<!--                            </p>-->
<!--                            <p>-->
<!--                                11. 개인정보처리방침 변경에 관한 사항-->

<!--                                회사는 개인정보처리방침을 개정하는 경우 최소 7일 전에 홈페이지의 공지사항을 통하여 고지할 것입니다.-->

<!--                            </p>-->
<!--                            <p>-->
<!--                                12. 개인정보에 관한 민원서비스-->

<!--                                기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.-->

<!--                                1) 개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)-->
<!--                                2) 개인정보분쟁조정위원회 (www.kopico.go.kr / 1833-6972)-->
<!--                                3) 대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301)-->
<!--                                4) 경찰청 사이버안전국 (cyberbureau.police.go.kr / 국번없이 182)-->


<!--                                본 개인정보처리방침에서 규정되지 않은 사항은 관계법령의 범위 내에서 당사 이용약관을 우선적으로 적용합니다.-->
<!--                                - 개인정보처리방침 공고일자 : 2020년 7월 27일-->
<!--                                - 개인정보처리방침 시행일자 : 2020년 8월 3일-->
<!--                            </p>-->
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs>
        </v-card>
    </div>
</template>

<script>
    export default {
        name: 'provision',
    }
</script>